export default {
  init() {
    // JavaScript to be fired on all pages

    // MOBILE MENU
    const mobileToggle = document.querySelector('.mobile-toggle')
    let mobileMenu = document.querySelector('header nav')

    if(mobileToggle) {
      mobileToggle.addEventListener('click', function() {
        this.classList.toggle('open')
        mobileMenu.classList.toggle('open')
      })
    }

    // VIDEO MODAL
    const header = document.querySelector('header.banner')
    const videoSection = document.querySelector('section.video')
    const video = document.querySelector('.hidden-video')
    const iframe = document.querySelector('.hidden-iframe')
    const videoTrigger = document.querySelector('.video .play-button img')
    const pauseTrigger = document.querySelector('.pause-video')

    if(video) {
      if(videoTrigger) {
        videoTrigger.addEventListener('click', function() {
          if(window.innerWidth > 700) {
            video.classList.add('open')
            video.play()
          }
          if(window.innerWidth > 1200) {
            header.classList.add('hide')
          }
          if(window.innerWidth <= 700 && iframe) {
            iframe.classList.add('open')
            iframe.src += '&autoplay=1'
          }

          if(videoSection) {
            if(window.innerWidth > 1023) {
              videoSection.classList.add('hide-elements')  
            }
          }
        })
      }

      if(pauseTrigger) {
        pauseTrigger.addEventListener('click', function() {
          if(video) {
            video.classList.remove('open')
            video.pause()
            header.classList.remove('hide')
          }
          videoSection.classList.remove('hide-elements')
        })
      }
    }
    // const modalTrigger = document.querySelector('.video .play-button img')
    // const modal = document.querySelector('.modal')

    // if(modalTrigger) {
    //   modalTrigger.addEventListener('click', function() {
    //     modal.classList.add('open')
    //   })
    // }

    // if(modal) {
    //   modal.addEventListener('click', function() {
    //     this.classList.remove('open')

    //     let iframeSrc = this.querySelector('iframe').src
        
    //     if(iframeSrc) {
    //       this.querySelector('iframe').src = iframeSrc
    //     }
    //   })

    //   document.onkeydown = function(evt) {
    //     evt = evt || window.event;
    //     if (evt.keyCode == 27) {
    //       modal.classList.remove('open')
          
    //       let iframeSrc = modal.querySelector('iframe').src
        
    //     if(iframeSrc) {
    //       modal.querySelector('iframe').src = iframeSrc
    //     }
    //     }
    // };
    // }

    // INTERSECITON OBSERVER
    const tickets = document.querySelectorAll('.ticket')
    // const ticketSection = document.querySelector('#promotions')

    const obsever = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          entry.target.classList.add('rotate')
          entry.target.classList.add('show')
        }
      })
      console.log(entries)
    }, {
      // threshold: 1,
      // rootMargin: '500px',
    })

    tickets.forEach(ticket => {
      obsever.observe(ticket)
    })

    // obsever.observe(ticketSection)
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
